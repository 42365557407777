import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-contact">
                <h2>Contact Us</h2>
                <p>Email: info@null-tech.net</p>
                <p>Phone: +962 770-761-932</p>
            </div>
            <div className="footer-social">
                <h2>Follow Us</h2>
                <ul>
                <li><a href="https://www.linkedin.com/company/null-technology" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                <li><a href="https://www.instagram.com/null.techno/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
