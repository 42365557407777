import React from 'react';
import './Home.css';
const Home = () => {
    return (
        <div>
            <h1>Revolutionize Your Workflow:</h1>
            <h2> Null Tech's Cutting-Edge Procurement Automation</h2>
            <p>At Null Tech, we’re dedicated to turning complexity into simplicity. Our advanced automation software is designed to revolutionize your business processes, eliminating tedious tasks and driving efficiency. By automating routine workflows, we empower you to focus on innovation and growth. Embrace a future where your operations are seamlessly streamlined, and your productivity soars with Null Tech.</p>
        </div>
    );
};

export default Home;
