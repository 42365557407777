import React from 'react';
import './Approach.css';
import approach from '../assets/approach.png';
const Approach = () => {
    return (
            <img width='80%' height='80%' src={approach}  alt='approach' style={{padding:'5%'}}/>
    );
};

export default Approach;
