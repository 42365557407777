import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phoneNumber: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phoneNumber: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_c2tzbyo', 'template_09ykok9', e.target, 'tRnW--qZnQlJcoP8G')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                setFormData({ name: formData.name, email: formData.email, phoneNumber: formData.phoneNumber, message: formData.message });
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message, please try again.');
            });
    };

    return (
        <div className="contact-container">
            <h1>Contact Us</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

                <label htmlFor="phoneNumber">Phone Number</label>
                <PhoneInput
                    className='contact-us-fi'
                    country={'jo'}
                    value={formData.phoneNumber}
                    onChange={handlePhoneChange}
                    inputProps={{
                        name: 'phoneNumber',
                        required: true,
                        autoFocus: true,
                    }}
                />
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>

                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default Contact;
