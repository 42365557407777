import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Approach from './components/Approach';
import Clients from './components/Clients';
import Technology from './components/Technology';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';

function AppContent() {
    const location = useLocation();
    const getBackgroundColor = () => {
        switch (location.pathname) {
            case '/':
                return 'home-background';
            case '/approach':
                return 'approach-background';
            case '/clients':
                return 'clients-background';
            case '/technology':
                return 'technology-background';
            case '/contact':
                return 'contact-background';
            default:
                return '';
        }
    };
    return (
        <div className="App">
            <Navbar />
            <main className={getBackgroundColor()}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/approach" element={<Approach />} />
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/technology" element={<Technology />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </main>
            {location.pathname !== '/' && <Footer />}
        </div>
    );
}

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;
