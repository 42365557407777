import React from 'react';

const Clients = () => {
    return (
        <div>
            <h1>Our Clients</h1>
            <p>We have worked with a wide range of clients from various industries.</p>
        </div>
    );
};

export default Clients;
