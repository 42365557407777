import React from 'react';
import './Technology.css';

// Importing images from the techstack folder
import dockerLogo from '../assets/techstack/docker.png';
import firebaseLogo from '../assets/techstack/firebase.png';
import flutterLogo from '../assets/techstack/flutter.png';
import googlecloudLogo from '../assets/techstack/googlecloud.jpeg';
import graphqlLogo from '../assets/techstack/graphql.png';
import javascriptLogo from '../assets/techstack/javascript.png';
import mongodbLogo from '../assets/techstack/mongodb.png';
import mysqlLogo from '../assets/techstack/mysql.png';
import nestjsLogo from '../assets/techstack/nestjs.png';
import nodejsLogo from '../assets/techstack/nodejs.png';
import oraclecloudLogo from '../assets/techstack/oracle-cloud.png';
import postgresqlLogo from '../assets/techstack/postgresql.png';
import pythonLogo from '../assets/techstack/python.png';
import reactLogo from '../assets/techstack/react.jpeg';
import typescriptLogo from '../assets/techstack/typescript.png';
import vuejsLogo from '../assets/techstack/vuejs.jpeg';
import laravelLogo from '../assets/techstack/laravel.png';
import awsLogo from '../assets/techstack/aws.jpeg';
import dartLogo from '../assets/techstack/dart.png';
import djangoLogo from '../assets/techstack/django.png';

const technologies = [
    { name: 'Docker', src: dockerLogo },
    { name: 'Firebase', src: firebaseLogo },
    { name: 'Flutter', src: flutterLogo },
    { name: 'Google Cloud', src: googlecloudLogo },
    { name: 'GraphQL', src: graphqlLogo },
    { name: 'JavaScript', src: javascriptLogo },
    { name: 'MongoDB', src: mongodbLogo },
    { name: 'MySQL', src: mysqlLogo },
    { name: 'NestJS', src: nestjsLogo },
    { name: 'Node.js', src: nodejsLogo },
    { name: 'Oracle Cloud', src: oraclecloudLogo },
    { name: 'PostgreSQL', src: postgresqlLogo },
    { name: 'Python', src: pythonLogo },
    { name: 'React', src: reactLogo },
    { name: 'TypeScript', src: typescriptLogo },
    { name: 'Vue.js', src: vuejsLogo },
    { name: 'Laravel', src: laravelLogo },
    { name: 'AWS', src: awsLogo },
    { name: 'Dart', src: dartLogo },
    { name: 'Django', src: djangoLogo },
];

const Technology = () => {
    return (
        <div className="technology-container">
            <div className="technology-content">
                <div className="technology-text">
                    <h1>Technologies We Use</h1>
                    <p>
                        We have experience with many frameworks, languages, and tools, and we can assemble the perfect set for your project. Here are some of the ones we use often:
                    </p>
                </div>
                <div className="technology-images">
                    <div className="technology-grid">
                        {technologies.map((tech) => (
                            <div key={tech.name} className="technology-item">
                                <img src={tech.src} alt={tech.name} className="technology-image" />
                                <p>{tech.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Technology;
