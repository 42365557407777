import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <Link to="/"> 
                <div className="navbar-logo">
                    <img src={logo} alt="Null Tech Logo" />
                </div>
            </Link>
            <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
                <li><Link to="/approach" onClick={toggleMenu}>Approach</Link></li>
                {/*<li><Link to="/clients" onClick={toggleMenu}>Clients</Link></li>*/}
                <li><Link to="/technology" onClick={toggleMenu}>Technology</Link></li>
                <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            </div>
            <div className="burger" onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </nav>
    );
};

export default Navbar;
